import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'src/components/layouts/default';
import BlockReferForm from 'src/components/blocks/blockReferForm.js';
import * as classes from './refer.module.scss';

export const query = graphql`
  query {
    datoCmsStart {
      referTitle
      referLede {
        blocks
        links
        value
      }
      referPatientHeader
      referReferrerHeader
      referOtherHeader
      referOtherPreformContent {
        blocks
        links
        value
      }
      referButtonText
      referCompleteContent {
        blocks
        links
        value
      }
    }
  }
`;

const ReferPage = ({ data, location }) => (
  <Layout location={location} pageTitle={data.datoCmsStart.referTitle}>
    <div className={classes.body}>
      <BlockReferForm
        preformContent={data.datoCmsStart.referLede}
        patientHeader={data.datoCmsStart.referPatientHeader}
        referrerHeader={data.datoCmsStart.referReferrerHeader}
        otherHeader={data.datoCmsStart.referOtherHeader}
        otherPreformContent={data.datoCmsStart.referOtherPreformContent}
        buttonText={data.datoCmsStart.referButtonText}
        onComplete={() => navigate(
          '/complete',
          {
            state: {
              pageTitle: data.datoCmsStart.referTitle,
              body: data.datoCmsStart.referCompleteContent,
            },
          }
        )}
      />
    </div>
  </Layout>
);

export default ReferPage;
